import dynamic from 'next/dynamic';
import React from 'react';
import { useSelector } from 'react-redux';
const DashboardNavigation = dynamic(() => import('./DashboardNavigation'), {ssr: false});
const UserRequests = dynamic(() => import("../../Custom/UserRequests"), {ssr: false});

const DashbardAppWrapper = ({children}) => {
    const user = useSelector(state => state.authReducer.user);
    const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    
    return (
        (isAuthenticated) && (user.email_verified_at != null) ? (
            <>
                <UserRequests/>
                <DashboardNavigation/>
            </>
        ) : null
    )
}

export default React.memo(DashbardAppWrapper)